import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'

import ContactForm from '@/components/Forms/Contact'
import Layout from '@/components/Layout'
import SEO from '@/helpers/SEOHelper.js'

export const ContactPageTemplate = ({ data }) => {
  return (
    <div className='full-height'>
      <section className='flex hero items-center justify-center pt-56 mb-10'>
        <div className='container text-center mx-auto relative'>
          <h1 className='title-1 font-bold max-w-2xl leading-none text-center mx-auto mb-6'>
            {ReactHtmlParser(data.hero.heading)}
          </h1>
          <p className='max-w-xl mx-auto mb-10 text-gray'>
            {ReactHtmlParser(data.hero.description)}
          </p>
        </div>
      </section>
      <section className='container pb-20'>
        <div className='max-w-3xl bg-gray-lightest mx-auto px-5 py-4 sm:px-32 sm:py-16'>
          <ContactForm formTitle='Contact Form'></ContactForm>
        </div>
      </section>
    </div>
  )
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <meta
          name='description'
          content={SEO.description(frontmatter.seo.description)}
        />
        <meta property='og:title' content={SEO.title(frontmatter.seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(frontmatter.seo.description)}
        />
        <meta property='og:image' content={SEO.image('null')} />
        <title>{SEO.title(frontmatter.seo.title)}</title>
      </Helmet>
      <ContactPageTemplate data={frontmatter} />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
      frontmatter {
        hero {
          heading
          description
        }
        seo {
          title
          description
        }
      }
    }
  }
`
